type Config = {
  backendUrl: string;
  frontendUrl: string;
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
};

const devConfig: Config = {
  backendUrl: 'http://localhost:8080',
  frontendUrl: 'http://localhost:8000',
  firebaseConfig: {
    apiKey: 'AIzaSyDDfsl9751AHV306yvzfOxrUY6_5hCv_Gs',
    authDomain: 'sigq-systems-development.firebaseapp.com',
    projectId: 'sigq-systems-development',
    storageBucket: 'sigq-systems-development.appspot.com',
    messagingSenderId: '143742277143',
    appId: '1:143742277143:web:e7f2f8bb70e9df89e9ae52',
    measurementId: '',
  },
};

const prodConfig: Config = {
  backendUrl: 'https://tracker-backend-746352252516.asia-northeast1.run.app',
  frontendUrl: 'https://console.sigq.io',
  firebaseConfig: {
    apiKey: 'AIzaSyA9dEp8T2IBIBxzc_KYTA4gT_iznyCaNiI',
    authDomain: 'console.sigq.io',
    projectId: 'sigq-production',
    storageBucket: 'sigq-production.appspot.com',
    messagingSenderId: '746352252516',
    appId: '1:746352252516:web:7ee10d28276093a2537171',
    measurementId: 'G-GD8TC9MT2M',
  },
};

const isLocal = () => {
  return ['localhost', '127.0.0.1'].includes(window.location.hostname);
};

export const envConfig: Config = isLocal() ? devConfig : prodConfig;
