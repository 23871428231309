import { useEffect } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import ResourceAccessControlList from './pages/ResourceAccessControlList';
import ResourceAccessControl from './pages/ResourceAccessControl';
import Receive from './pages/Receive';
import { useState } from 'react';
import { lazy } from 'react';
import { Organization } from './types';
import { firebaseAuth } from './firebaseAuth';
import { User } from 'firebase/auth';
import UserSetting from './pages/UserSetting';
import OrganizationSetting from './pages/OrganizationSetting';
import OperationLogList from './pages/OperationLog';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import SharingResource from './pages/SharingResource';
import NoAuthLayout from './components/NoAuthLayout';
import { level2ApiEndpoint } from './utils/apiEndpointUrl';
import { requestToBackend } from './utils/rpcLogics';
import I18n from './i18n';
import AccessLogExplorer from './pages/AccessLogExplorer';
import LP from './pages/LP';
import CompanyInfo from './pages/CompanyInfo';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SpecifiedCommercialTransactions from './pages/SpecifiedCommercialTransactions';
import PageNotFound from './components/PageNotFound';
import Welcome from './pages/Welcome';
import Term from './pages/Term';

const ResourceList = lazy(() => import('./pages/ResourceList'));
const SidebarLayout = lazy(() => import('./components/SidebarLayout'));

function App() {
  const currentUrl = new URL(window.location.href);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [currentOrganization, setCurrentOrganization] = useState<
    Organization | undefined
  >(undefined);
  const [organizationOptions, setOrganizationOptions] = useState<
    Organization[]
  >([]);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  firebaseAuth.onAuthStateChanged(function (user) {
    if (user) {
      setCurrentUser(user);
    }
  });

  setTimeout(() => {
    if (!isPageLoaded) {
      setLoading(false);
      setIsPageLoaded(true);
    }
  }, 1000);

  const ignoreAuthPath = [
    '/',
    '/company',
    '/privacy-policy',
    '/specified-commercial-transactions',
    '/receive',
    '/term',
  ];

  useEffect(() => {
    if (!currentUser || isLoading) {
      return;
    }
    if (ignoreAuthPath.includes(window.location.pathname)) {
      return;
    }
    (async () => {
      setLoading(true);
      const [response, error1] = await requestToBackend(
        currentUser,
        `${level2ApiEndpoint()}/user`,
        'GET'
      );
      if (error1) {
        console.log(I18n.t(`error.${error1}`));
        return;
      }
      if (!response) {
        return;
      }
      const user: User = await response.json();
      if (!user) {
        return;
      }

      setCurrentUser(user);
      const [response2, error2] = await requestToBackend(
        currentUser,
        `${level2ApiEndpoint()}/organizations`,
        'GET'
      );
      if (error2) {
        console.log(I18n.t(`error.${error2}`));
        setIsPageLoaded(true);
        return;
      }
      if (!response2) {
        return;
      }
      const organizations: Organization[] = await response2.json();
      if (!organizations) {
        setIsPageLoaded(true);
        return;
      }
      setOrganizationOptions(organizations);
      const currentOrganizationId =
        currentUrl.searchParams?.get('organizationId');
      const currentOrganization = organizations.filter(
        (t) => t.id === currentOrganizationId
      )[0];
      if (currentOrganization) {
        setCurrentOrganization(currentOrganization);
      } else {
        currentUrl.searchParams.set('organizationId', organizations[0].id);
        window.location.href = currentUrl.href;
        setCurrentOrganization(organizations[0]);
      }
      setIsPageLoaded(true);
    })();
  }, [currentUser]);

  if (!isPageLoaded) {
    return <div></div>;
  } else if (currentUser && currentOrganization) {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<NoAuthLayout />}>
            <Route path="" element={<LP />} />
            <Route path="/company" element={<CompanyInfo />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/term" element={<Term />} />
            <Route
              path="/specified-commercial-transactions"
              element={<SpecifiedCommercialTransactions />}
            />
          </Route>
          <Route
            element={
              <SidebarLayout
                organizationOptions={organizationOptions}
                currentOrganization={currentOrganization}
                currentUser={currentUser}
              />
            }
          >
            {/* <Route path="/" element={<DocumentList currentUser={currentUser} />} /> */}
            <Route path="/receive" element={<Receive />} />{' '}
            <Route
              path="/welcome"
              element={
                <Welcome
                  currentOrganization={currentOrganization}
                  currentUser={currentUser}
                />
              }
            />
            <Route
              path="/tracking"
              element={
                <ResourceAccessControlList
                  currentOrganization={currentOrganization}
                  currentUser={currentUser}
                />
              }
            />
            <Route
              path="/tracking/:resourceId/resourceAccessControls"
              element={
                <ResourceAccessControl
                  currentOrganization={currentOrganization}
                  currentUser={currentUser}
                />
              }
            />
            <Route
              path="/tracking/:resourceId/resourceAccessControls/:resourceAccessControlId"
              element={
                <SharingResource
                  currentOrganization={currentOrganization}
                  currentUser={currentUser}
                />
              }
            />
            <Route
              path="/access-log-explorer"
              element={
                <AccessLogExplorer
                  currentUser={currentUser}
                  currentOrganization={currentOrganization}
                />
              }
            />
            <Route
              path="/resources"
              element={
                <ResourceList
                  currentUser={currentUser}
                  currentOrganization={currentOrganization}
                />
              }
            />
            <Route
              path="/user-setting"
              element={
                <UserSetting
                  currentUser={currentUser}
                  currentOrganization={currentOrganization}
                />
              }
            />
            <Route
              path="/organization-setting"
              element={
                <OrganizationSetting
                  currentUser={currentUser}
                  currentOrganization={currentOrganization}
                />
              }
            />
            <Route
              path="/operation-log"
              element={
                <OperationLogList
                  currentUser={currentUser}
                  currentOrganization={currentOrganization}
                />
              }
            />
          </Route>

          <Route
            path="*"
            element={
              <Navigate
                replace
                to={`/resources?organizationId=${currentOrganization.id}`}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    );
    // アカウントを作った直後
  } else {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route element={<NoAuthLayout />}>
              <Route path="/receive" element={<Receive />} />
              <Route path="/company" element={<CompanyInfo />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/term" element={<Term />} />
              <Route
                path="/specified-commercial-transactions"
                element={<SpecifiedCommercialTransactions />}
              />
              <Route path="" element={<LP />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
