import { User } from 'firebase/auth';
import { Organization, WelcomePageStats } from '../types';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { requestToBackend } from '../utils/rpcLogics';
import { level3ApiEndpoint } from '../utils/apiEndpointUrl';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}
export default function Welcome({
  currentUser,
  currentOrganization,
}: {
  currentUser: User;
  currentOrganization: Organization;
}) {
  const [welcomePageStats, setWelcomePageStats] = useState<WelcomePageStats>();
  useEffect(() => {
    if (!currentUser || !currentOrganization) {
      return;
    }
    (async () => {
      const [response, error] = await requestToBackend(
        currentUser,
        `${level3ApiEndpoint()}/welcome-page-stats?organizationId=${
          currentOrganization.id
        }`,
        'GET'
      );
      if (!response) {
        return;
      }
      const stats: WelcomePageStats = await response.json();
      setWelcomePageStats(stats);
    })();
  }, []);

  const stats = [
    { name: 'ファイル数', stat: welcomePageStats?.numberOfResources || 0 },
    {
      name: '共有リンク数',
      stat: welcomePageStats?.numberOfResourceAccessContols || 0,
    },
    {
      name: '過去30日の共有リンクアクセス数',
      stat: welcomePageStats?.numberOfResourceAccessHistory || 0,
    },
  ];

  const organizationSettingStatus = (): 'complete' | 'todo' => {
    if (welcomePageStats?.isOrganizationSettingDone) {
      return 'complete';
    }

    return 'todo';
  };

  const trialStatus = (): 'complete' | 'todo' => {
    if (welcomePageStats?.isTrialUseDone) {
      return 'complete';
    }

    return 'todo';
  };

  const steps = [
    {
      name: '新規登録をする',
      description: 'Google アカウントでSIGQ Linkerへ新規登録します。',
      href: '#',
      status: 'complete',
    },
    {
      name: '早速使ってみる',
      description:
        '色々な設定の前に、まずはSIGQ Linkerを早速使ってファイルを1枚アップロード・送信してみましょう。',
      href: '/resources?organizationId=' + currentOrganization.id,
      status: trialStatus(),
    },
    {
      name: '組織の設定をする',
      description:
        '次に組織の設定をしましょう。組織は管理者の方のみが編集できる項目です。',
      href: '/organization-setting?organizationId=' + currentOrganization.id,
      status: organizationSettingStatus(),
    },
  ];

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-8">
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">現在のステップ 🗺️📍</div>
        <div className="px-4 py-5 sm:p-6">
          <nav aria-label="Progress">
            <ol role="list" className="overflow-hidden">
              {steps.map((step, stepIdx) => (
                <li
                  key={step.name}
                  className={classNames(
                    stepIdx !== steps.length - 1 ? 'pb-10' : '',
                    'relative'
                  )}
                >
                  {step.status === 'complete' ? (
                    <a
                      href={step.href}
                      className="group relative flex items-start"
                    >
                      <span className="flex h-9 items-center">
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                          <CheckIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-white"
                          />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium">{step.name}</span>
                        <span className="text-sm text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </a>
                  ) : (
                    <a
                      href={step.href}
                      className="group relative flex items-start"
                    >
                      <span
                        aria-hidden="true"
                        className="flex h-9 items-center"
                      >
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                          <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-gray-500">
                          {step.name}
                        </span>
                        <p className="text-sm text-gray-500">
                          {step.description}
                        </p>
                      </span>
                    </a>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-10">
        <div className="px-4 py-5 sm:px-6">ご利用状況 🏃</div>
        <div className="px-4 py-5 sm:p-6">
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.name}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">
                  {item.name}
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {item.stat}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
