import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { Container } from './Container';
import { level1ApiEndpoint } from '../../utils/apiEndpointUrl';
import { useState } from 'react';
import I18n from '../../i18n';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

export function Hero() {
  const auth = getAuth();
  auth.languageCode = 'ja';
  const [httpResponseResult, setHttpResponseResult] = useState({
    success: '',
    error: '',
  });
  const urlParams = new URLSearchParams(window.location.search);
  const allowSignup = urlParams.get('allowSignup');
  const continueWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (!credential) {
          return;
        }
        const token = credential.accessToken;
        if (!token) {
          return;
        }
        const formData = new FormData();
        const user = result.user;
        if (!user.email) {
          return;
        }
        formData.append('email', user.email);
        user
          .getIdToken(true)
          .then((token) => {
            fetch(`${level1ApiEndpoint()}/create-session`, {
              headers: { Authorization: token },
              method: 'POST',
              body: formData,
              credentials: 'include',
            })
              .then((response) => {
                if (response.ok) {
                  const message = structuredClone(httpResponseResult);
                  message.success = I18n.t(
                    'individual_pages.sign_up.successfully_created_user'
                  );
                  setHttpResponseResult(message);
                  window.location.replace('/welcome');
                } else {
                  throw new Error(
                    '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                  );
                }
              })
              .catch(() => {
                throw new Error(
                  '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                );
              });
          })
          .catch((e: Error) => {
            const message = structuredClone(httpResponseResult);
            message.error = e.message;
            setHttpResponseResult(message);
          });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  return (
    <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
      <div
        aria-hidden="true"
        className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
      />
      <div className="mx-auto max-w-7xl px-6 py-10 sm:py-20 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
          <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
            <span className="relative whitespace-nowrap text-blue-600">
              <svg
                aria-hidden="true"
                viewBox="0 0 418 42"
                className="absolute left-0 top-2/3 h-[0.58em] w-full fill-blue-300/70"
                preserveAspectRatio="none"
              >
                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
              </svg>
              <span className="relative">安全な</span>
            </span>
            ファイル共有
          </h1>
          <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
            <p className="text-lg leading-8 text-gray-600">
              Linkerは重要なファイルを安全に共有をできるツールです。
            </p>
            <p className="text-lg leading-8 text-gray-600">
              共有したファイルへ「誰が」「いつ」アクセスしたかを簡単に確認することもできます。
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <Popover
                className="relative mt-10"
                hidden={!Boolean(allowSignup)}
              >
                <PopoverButton className="inline-flex items-center gap-x-1 text-x leading-6 text-gray-900">
                  <span>新規会員登録・ログイン</span>
                  <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
                </PopoverButton>

                <PopoverPanel
                  transition
                  className="absolute z-10 mt-5 flex w-screen max-w-80 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                      <div className="my-5  flex justify-center gap-x-6">
                        <button className="w-60" onClick={continueWithGoogle}>
                          <img
                            src="/images/web_light_rd_ctn@4x.png"
                            loading="lazy"
                          />
                        </button>
                      </div>

                      <p>
                        「Continue with Google」をクリックすることで、
                        <a
                          href="/term"
                          className="hover:underline"
                          target="_blank"
                        >
                          {I18n.t('noun.terms')}
                        </a>
                        と
                        <a
                          href="/privacy-policy"
                          className="hover:underline"
                          target="_blank"
                        >
                          {I18n.t('noun.privacy_policy')}
                        </a>
                        に同意したものとみなします。また、必要に応じて、メールで広告、プロモーション、その他の通知を送信することがあります。
                      </p>
                    </div>
                  </div>
                </PopoverPanel>
              </Popover>
            </div>
          </div>
          <img
            alt=""
            src="/images/hero-files.png"
            className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
          />
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
    </div>
  );
}
