import { useEffect, useState } from 'react';
import i18n from '../i18n';
import Notification from '../components/Notification';
import { Organization } from '../types';
import { Resource } from '../classes/resource';
import { level2ApiEndpoint, level3ApiEndpoint } from '../utils/apiEndpointUrl';
import { requestToBackend } from '../utils/rpcLogics';
import { User } from 'firebase/auth';
import { parseDate } from '../utils/date';
import { envConfig } from '../constants/config';

export default function ResourceAccessControlList({
  currentUser,
  currentOrganization,
}: {
  currentUser: User;
  currentOrganization: Organization;
}) {
  const [resources, setResources] = useState<Resource[]>([]);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [openNotification, setOpenNotification] = useState(false);

  useEffect(() => {
    if (!currentUser || !currentOrganization) {
      return;
    }
    (async () => {
      const [response, error] = await requestToBackend(
        currentUser,
        `${level3ApiEndpoint()}/resources?organizationId=${
          currentOrganization.id
        }`,
        'GET'
      );
      if (!response) {
        return;
      }
      const resources = await response.json();
      setResources(resources);
    })();
  }, []);

  const copyLink = (str: string) => {
    const url = `${envConfig.frontendUrl}/receive?id=${str}`;
    navigator.clipboard.writeText(url).then(() => {
      setOpenNotification(true);
      setInfoMessage(i18n.t('notification.copied_to_clipboard'));
    });
  };

  const deleteResourceAccessControl = (
    resourceId: string,
    resourceAccessControlId: string
  ) => {
    const confirmMessage = i18n.t(
      'confirmation.are_you_sure_to_delete_resource'
    );
    if (!confirm(confirmMessage)) {
      return;
    }
    const formData = new FormData();
    formData.append('resourceAccessControlId', resourceAccessControlId);
    if (!currentUser || !currentOrganization) {
      return;
    }
    (async () => {
      const [_, error] = await requestToBackend(
        currentUser,
        `${level3ApiEndpoint()}/resources/${resourceId}/resourceAccessControls/${resourceAccessControlId}?organizationId=${
          currentOrganization.id
        }`,
        'DELETE',
        formData
      );
      setOpenNotification(true);
      if (!error) {
        setInfoMessage(i18n.t('notification.success_delete'));
      } else {
        setAlertMessage(i18n.t(`error.${error}`));
      }
    })();
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8 content-center w-full">
      {infoMessage && (
        <Notification
          infoMessage={infoMessage}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
      )}
      {alertMessage && (
        <Notification
          alertMessage={alertMessage}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
      )}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {i18n.t('noun.sharing_resources')}
          </h1>
        </div>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none text-right pt-3 text-gray-400 font-light">
        {i18n.t('noun.timezone')}:{' '}
        {Intl.DateTimeFormat().resolvedOptions().timeZone}
      </div>

      <div className="mt-1 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {i18n.t('noun.sharing_resources')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      style={{ minWidth: '120px' }}
                    >
                      {i18n.t('noun.access_count')}
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('noun.expiration')}
                    </th>
                    <th scope="col" className="relative">
                      <span className="sr-only">Share link</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {resources.map((resource) =>
                    resource.resourceAccessControls.map(
                      (resourceAccessControl) => (
                        <tr key={resource.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <a
                              href={`/tracking/${resource.id}/resourceAccessControls/${resourceAccessControl.id}?organizationId=${currentOrganization.id}`}
                            >
                              {resource.name}
                            </a>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            0
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {parseDate(resourceAccessControl.expireAt)}
                          </td>
                          <td className="relative whitespace-nowrap py-1 pl-1 pr-1 text-right text-sm font-medium">
                            <button
                              className="text-gray-600 hover:text-gray-900"
                              onClick={() => {
                                copyLink(resourceAccessControl.id);
                              }}
                            >
                              {i18n.t('action.copy')}
                            </button>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              className="text-gray-600 hover:text-gray-900"
                              onClick={() => {
                                deleteResourceAccessControl(
                                  resource.id,
                                  resourceAccessControl.id
                                );
                              }}
                            >
                              {i18n.t('action.delete')}
                            </button>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
